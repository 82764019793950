
import "./choa/choa.sass"

export default function Contact() {
    return(
        <div className="full-w full-vh flex-c padding-frame m-b-l">
            
            <div className="full-w m-t-m flex-c flex-h flex-wrap">
                
                <div className="btn-basic m-a-s" style={{ width: "100%", maxWidth:"600px"}}>
                    <div className="full-w p-a-m">
                        <h2 className="flex-c fw7"> Contact Us </h2>
                    </div>
                    <div className="p-a-m tc-dark1 full-w flex-c btn-tinted">
                        <p className="tc-dark2">If you have any questions please call us or email us.
                            We won’t keep you waiting. You’ll hear from us as soon as possible during normal business hours and always within 24 hours of enquiring.
                            </p>
                    </div>
                    <div className="flex-v p-a-m">
                        <h4 className="flex-h flex-sb">Email: <span className="m-l-s fw-7">knightframeproperties@gmail.com</span></h4>
                        <h4 className="flex-h flex-sb">Phone number: <span className="m-l-s fw-7">0044 7519554748 </span> </h4>
                        <h4 className="flex-h flex-sb">Office: <span className="m-l-s fw-7">146 St. Heliers Road, B31 1QP, Birmingham </span> </h4>
                    </div>
                </div>

            </div>

        </div>
    );
}
