
import { NavLink} from "react-router-dom";
import "./choa/choa.sass"
import herobackground from "./icons/images/hero-background.jpg"

export default function HeroSection() {
    return(
        <div className="heroSection darkmode">
            <img alt="" src={herobackground} />

                <div className="hero-info-left">
                    <h1 className="title fw-7 tc-1">
                        Flexible housing <br/> that fits your needs.
                    </h1>
                    <h4 className="text full-w fw-5 m-t-m tc-1">
                        We offer short term lets in between longer term tenancies. 
                    </h4>

                    <div className="buttons m-t-m flex">
                        <NavLink to="/locations" className="btn btn-stroke">
                            <h4 className="m-r-xs m-l-xs"> See Locations </h4>
                        </NavLink>
                    </div>   
                </div>

        </div>
    );
}

