import { NavLink} from "react-router-dom";
import "./choa/choa.sass"

export default function Locations() {
    return(
        <div className="full-w min-vh padding-frame m-b-l">
            <div className="full-w flex-c m-t-m p-t-s p-b-s" > <h2 className="fw-7"> LOCATIONS </h2></div>

            <div className="full-w m-t-m flex-c flex-h flex-wrap">
                
                <div className="btn-basic m-a-s" style={{ width: "100%", maxWidth:"300px"}}>
                    <div className="full-w flex-c btn-tinted" style={{minHeight: "200px"}}>
                        <h3>Coming Soon</h3>
                    </div>
                    <div className="flex-v p-a-s">
                        <h5>DY4 9JN, Tipton, West Midlands</h5>
                        <h3 className="m-t-s fw-7">3 Bedroom House</h3>
                        <p className="m-t-s">Undergoing renovation.</p>
                    </div>
                    <NavLink to="/comingsoon" className="btn btn-basic flex-c">
                        <h4> Know more </h4>
                    </NavLink>
                </div>

                <div className="btn-basic m-a-s" style={{ width: "100%", maxWidth:"300px"}}>
                    <div className="full-w flex-c btn-tinted" style={{minHeight: "200px"}}>
                        <h3>Coming Soon</h3>
                    </div>
                    <div className="flex-v p-a-s">
                        <h5>DY4 9JN, Tipton, West Midlands</h5>
                        <h3 className="m-t-s fw-7">Detached House</h3>
                        <p className="m-t-s">Pending approval.</p>
                    </div>
                    <NavLink to="/comingsoon" className="btn btn-basic flex-c">
                        <h4> Know more </h4>
                    </NavLink>
                </div>

                <div className="btn-basic m-a-s"  style={{ width: "100%", maxWidth:"300px"}}>
                    <div className="full-w flex-c btn-tinted" style={{minHeight: "200px"}}>
                        <h3>Coming Soon</h3>
                    </div>
                    <div className="flex-v p-a-s">
                        <h5>Birmingham city centre</h5>
                        <h3 className="m-t-s fw-7">2 Bed Apartment</h3>
                        <p className="m-t-s">Coming soon.</p>
                    </div>
                    <NavLink to="/comingsoon" className="btn btn-basic flex-c">
                        <h4> Know more </h4>
                    </NavLink>
                </div>

            </div>

        </div>
    );
}
