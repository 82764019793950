import Router from "./Router.js"

function App() {
  return (
      <div className="">
        <Router />
      </div>
  );
}

export default App;