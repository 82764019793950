import React from "react";
import { useRef, useState } from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";

import TopBar from "./TopBar";
import HeroSection from "./HeroSection";
import Locations from "./Locations";
import Contact from "./Contact";
import AboutuS from "./AboutUs";
import Footer from "./Footer";
import ComingSoon from "./ComingSoon";

function Router() {

    const mainScroll = useRef();
    const [isTop, setIsTop] = useState(true);

    function updateScroll(){
        let currentScroll = mainScroll.current.scrollTop;

        if(currentScroll > 30) setIsTop(false);
        else setIsTop(true);
    };

    return (
        <BrowserRouter>
            <div className="relative scroll-y full-w full-vh" ref={mainScroll} onScroll={updateScroll}>
                <Switch>

                    <Route exact path="/">
                        <TopBar isTop={isTop}/>
                        <HeroSection />
                        <Locations />
                        <Contact />
                        <Footer />
                    </Route>

                    <Route path="/locations">
                        <TopBar isTop={false}/>
                        <div style={{minHeight: "80px"}}></div>
                        <Locations />
                        <Footer />
                    </Route>

                    <Route path="/contacts">
                        <TopBar isTop={false}/>
                        <Contact />
                        <Footer />
                    </Route>

                    <Route path="/about-us">
                        <TopBar isTop={false}/>
                        <AboutuS />
                        <Footer />
                    </Route>

                    <Route path="/comingsoon">
                        <TopBar isTop={false}/>
                        <ComingSoon />
                        <Footer />
                    </Route>

                </Switch>
            </div>
        </BrowserRouter>
    );
};

export default Router;