
import "./choa/choa.sass"

export default function ComingSoon() {

    return(
        <div className="full-w min-vh flex-c padding-frame m-b-l">
            
            <div className="full-w m-t-m flex-c flex-h flex-wrap">
                
                <div className="btn-basic m-a-s" style={{ width: "100%", maxWidth:"600px"}}>
                    <div className="full-w p-a-m">
                        <h2 className="flex-c fw7"> Work in progress. </h2>
                    </div>
                    <div className="p-a-m tc-dark1 full-w flex-c btn-tinted">
                        <p className="tc-dark2"> Looks like this page is not complete yet. <br/> Please come back on a later date.
                        </p>
                    </div>
                </div>

            </div>

        </div>
    );
}
