
import {useState } from "react";
import { NavLink} from "react-router-dom";
import "./choa/choa.sass"
import SvgIcons from "./icons/SvgIcons.js"
import logoblack from "./icons/images/knightframe-properties-logo-black.png"
import logowhite from "./icons/images/Knightframe-properties-logo-white.png"


export default function TopBar({isTop}){

    const [isOpen, setOpen] = useState(false);

    return(
        <div className={isTop? isOpen? "top-menu lightmode" : "top-menu top-unscrolled darkmode" : "top-menu lightmode"}>
            <StudioMenu isTop={isTop} isOpen={isOpen}/>
            <MobileMenu isTop={isTop} isOpen={isOpen} setOpen={setOpen} />
        </div>
    );
}

export function StudioMenu({isTop}){
  return(
    <div className="studio-menu padding-frame max-d-l">
      <div className="left">
        <NavLink to="/locations" className="btn btn-plain m-r-xs">
              <h4> Locations </h4>
        </NavLink>
        <NavLink to="/comingsoon" className="btn btn-plain m-r-xs">
              <h4> Residents </h4>
        </NavLink>
        <NavLink to="/comingsoon" className="btn btn-plain">
              <h4> Property Owners </h4>
        </NavLink>
      </div>
      <div className="center">
        <NavLink to="/" className="mixa-logo btn img-xxl">
            <div className="logo">
                {isTop? <img alt="" src={logowhite}/> : <img alt="" src={logoblack}/>}
            </div>
        </NavLink>
      </div>
      <div className="right">
        <NavLink to="/about-us" className="btn btn-plain m-r-s">
              <h4> About Us </h4>
        </NavLink>
        <NavLink to="/contacts" className="btn btn-stroke">
            <h4 className=" m-t-xs m-b-xs m-l-s m-r-s"> Get In Touch </h4>
        </NavLink>
      </div>
    </div>
  );
}

export function MobileMenu({isTop, isOpen, setOpen}){
    
    const toggleMenu = ()=>{
        setOpen(!isOpen);
        console.log("opening");
    }

  return(
    <div className="mobile-menu padding-frame">
        <div className="bar">
            <NavLink to="/" className="mixa-logo btn img-xl" onClick={()=>setOpen(false)}>
                <div className="logo" >
                    {isTop? isOpen? <img alt="" src={logoblack}/> : <img alt="" src={logowhite}/> : <img alt="" src={logoblack}/>}
                </div>
            </NavLink>
            <btn className="btn btn-stroke" onClick={toggleMenu}>
                {isOpen? <SvgIcons iconName="x" />  : <SvgIcons iconName="hamburger" />}
            </btn>
        </div>
        <div className={isOpen? "menu menu-open" :"menu"}>
            <div className="full-w full-vh">
                <NavLink to="/locations" className="btn btn-plain m-t-m" onClick={()=>setOpen(false)}>
                    <h2> Locations </h2>
                </NavLink>
                <NavLink to="/comingsoon" className="btn btn-plain m-t-m" onClick={()=>setOpen(false)}>
                    <h2> Residents </h2>
                </NavLink>
                <NavLink to="/comingsoon" className="btn btn-plain m-t-m" onClick={()=>setOpen(false)}>
                    <h2> Property Owners </h2>
                </NavLink>
                <NavLink to="/about-us" className="btn btn-plain m-t-m" onClick={()=>setOpen(false)}>
                    <h2> About Us </h2>
                </NavLink>
                <NavLink to="/contacts" className="btn btn-plain m-t-m" onClick={()=>setOpen(false)}>
                    <h2> Contacts </h2>
                </NavLink>
                <NavLink to="/contacts" className="m-b-s full-w absolute bottom0 btn btn-stroke m-t-m" onClick={()=>setOpen(false)}>
                    <h3 className=" m-t-s m-b-s full-w flex-c"> Get In Touch </h3>
                </NavLink>
            </div>
        </div>
    </div>
  );
}