
import { NavLink} from "react-router-dom";
import "./choa/choa.sass"

export default function Footer() {
    return(
        <div className="full-w padding-frame btn-tinted">

            <div className="full-w flex-cv flex-h flex-sb p-b-xs p-t-xs tc-dark2">
                <NavLink to="/" className="btn btn-plain m-r-xs">
                    &copy; Knightframe Properties LTD 
                </NavLink>
                <NavLink to="contacts" className="btn btn-plain">
                    Contact Us
                </NavLink>
            </div>

        </div>
    );
}
