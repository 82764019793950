
import "./choa/choa.sass"

export default function AboutUs() {
    return(
        <div className="full-w full-vh flex-c padding-frame m-b-l">
            
            <div className="full-w m-t-m flex-c flex-h flex-wrap">
                
                <div className="btn-basic m-a-s" style={{ width: "100%", maxWidth:"600px"}}>
                    <div className="full-w p-a-m">
                        <h2 className="flex-c fw7"> Mission: </h2>
                    </div>
                    <div className="p-a-m btn-tinted full-w flex-c">
                        <p className="m-b-s tc-dark2 ">
                        Knightframe Properties maximises yield by blending short, mid and long lets. 
                        Our flexible renting model enables us to optimise our portfolio’s performance.
                        We are currently building an App to make the process of renting and moving as fluid as possible, please contact us if you would like to join our team.
                        </p>
                    </div>
                    <div className="flex-v p-a-m">
                        <h4>"Our model of filling void periods with short term lets in between longer term tenancies is a win/win for Residents as well as Landlords."</h4>
                        <h3 className="full-w m-t-s" style={{textAlign: "end"}}> Priyanka Shimar </h3>
                        <h5 className="full-w" style={{textAlign: "end"}}> Director </h5>
                    </div>
                </div>

            </div>

        </div>
    );
}
